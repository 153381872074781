.rnd-container {
    display: flex;
    align-items: left;
    justify-content: left;
    background: #fff;
}

.rnd-container .react-draggable {
    -moz-box-shadow: 2px 2px 12px 1px #ccc;
    -webkit-box-shadow: 2px 2px 12px 1px #ccc;
    box-shadow: 2px 2px 12px 1px #ccc;
    background: white;
}

.rnd-container .ant-dropdown-link {
    border: 1px solid grey;
    border-radius: 3px;
    background: rgb(239 239 239);
    padding: 2px 7px;
}