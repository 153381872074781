:root {
	--font-family: "Open Sans", sans-serif;
}

.height-100 {
	height: 100%;
}

.btn[disabled] {
	pointer-events: none;
	opacity: 0.6;
	background-color: gray;
}

::-webkit-scrollbar {
	width: 6px;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 80px;
	-webkit-border-radius: 80px;
}

::-webkit-scrollbar:hover {
	background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb:vertical {
	background: rgba(0, 0, 0, 0.61);
	border-radius: 80px;
	-webkit-border-radius: 80px;
}

::-webkit-scrollbar-thumb:vertical:active {
	background: rgba(0, 0, 0, 0.61);
	border-radius: 80px;
	-webkit-border-radius: 80px;
}

.react-tooltip-lite {
	background: #333;
	color: white;
	z-index: 10001 !important;
}

.react-tooltip-lite-arrow {
	border-color: #333;
	z-index: 10001 !important;
}
